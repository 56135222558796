<template>
    <div>
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                            <div>
                                <i class="icon-phone2 mr-1 align-middle"></i>
                                <span class="align-middle">{{rowReg.bu_phone||"-"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        
        <div class="content">
        <validation-observer ref="VFormSubmit">
            <b-form @submit.prevent="doSubmit">
                <div class="card border mb-3">
                <div @click="showPatient = !showPatient" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                    <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title font-weight-semibold">Lihat Informasi Pasien</h6>
                    <i class="icon-chevron-down"></i>
                    </div>
                </div>
                <div v-if="showPatient" id="infoPatient">
                    <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{row.ap_code||"-"}} </p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{row.ap_fullname||"-"}} </p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tgl. Lahir</h4>
                                <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Tinggi Badan</h4>
                                <p>{{dataDokter.apod_ttv_height||"- "}}cm</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Berat Badan</h4>
                                <p>{{dataDokter.apod_ttv_weight||"- "}}cm</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Luas Permukaan Badan</h4>
                                <p v-if="row.ap_usia > 15">{{dataDokter.apod_ttv_bmi||"- "}}m<sup>2</sup></p>
                                <p v-else>{{dataDokter.apod_ttv_luas_permukaan_anak||"- "}}m<sup>2</sup></p>
                            </div>
                            </td>
                        </tr>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div class="card">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Resep</h6>
                    </div>
                    
                    <div class="card-body p-3" v-if="dataDokter.ausd_has_alergi == 'Y' || dataDokter.ausd_has_obat_rutin == 'Y'">
                        <div class="row">                    
                        <div class="col-md-6" v-if="dataDokter.ausd_has_alergi == 'Y'">
                            <div class="card border mb-0">
                            <div class="card-header bg- head_panel_red p-2">
                                <h6 class="font-weight-semibold text-dark card-title">Catatan Alergi Pasien</h6>
                            </div>
                            <div class="card-body py-2 px-3">
                                <ul class="mb-0">
                                <li class="my-1" v-for="(al, alKey) in (dataDokter.ausd_alergi || [])" :key="alKey">
                                    <span class="font-weight-semibold" v-if="dataDokter.ausd_alergi[alKey]['jenis']">
                                    ({{ dataDokter.ausd_alergi[alKey]['jenis'] }})</span> <span>{{ dataDokter.ausd_alergi[alKey]['name']||"-" }}
                                    </span>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="dataDokter.ausd_has_obat_rutin == 'Y'">
                            <div class="card border mb-0">
                            <div class="card-header bg- head_panel_blue p-2">
                                <h6 class="font-weight-semibold text-dark card-title">Catatan Obat Rutin Pasien</h6>
                            </div>
                            <div class="card-body py-2 px-3">
                                <span class="my-1">{{ dataDokter.ausd_keterangan_obat_rutin||"-" }}</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="card-body pt-3">
                        <div class="row">
                            <div class="col-md-4" v-if="rowReg.aur_is_farmasi_done_at">
                                <div class="result_tab">
                                <h4>Tanggal Pembuatan Resep</h4>
                                <p>{{rowReg.aur_is_farmasi_done_at | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="result_tab">
                                <h4>Catatan Resep</h4>
                                <p>{{dataDokter.aupd_catatan_resep||"-"}}</p>
                                </div>
                            </div>
                            
                            <div class="col-md-4" v-if="rowReg.aur_is_farmasi_done_at">
                                <div class="result_tab">
                                <h4>Catatan Perubahan Resep</h4>
                                <p>{{rowReg.aur_catatan_perubahan_resep||"-"}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <b-table
                        bordered
                        striped
                        small
                        hover
                        :fields="farmTableFields"
                        :items="data"
                    >
                        <template #cell(aksi)="rowTable">
                            <template v-if="rowReg.aur_is_submit_farmasi != 'Y'">
                                <template v-if="rowTable.item.isEdit">
                                    <a href="javascript:;" @click="doSubmitObat(rowTable.item)" class="btn btn-sm alpha-success border-success text-success-800 btn-icon rounded-round" v-b-tooltip.hover title="Simpan Perubahan Obat"><i class="icon-checkmark"></i></a>

                                    <a @click="removeObat(rowTable.item,rowTable.index)" href="javascript:;" class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                                </template>
                                <template v-else>
                                    <a href="javascript:;" @click="rowTable.item.isEdit = true; openAddObat = true" 
                                            class="btn btn-sm alpha-success border-success text-success-800 btn-icon rounded-round"
                                            data-popup="tooltip" v-b-tooltip.hover title="Edit"><i class="icon-pencil7"></i></a>
                                    <a @click="removeObat(rowTable.item,rowTable.index)" href="javascript:;"
                                            class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"
                                            data-popup="tooltip" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                                </template>
                            </template>
                            <span v-else> - </span>
                        </template>
                        <template #cell(aurdf_jenis)="rowTable">
                            <template v-if="rowTable.item.isEdit">
                                <v-select
                                    placeholder="Pilih Item"
                                    v-model="data[rowTable.index].aurdf_jenis"
                                    :options="Config.mr.configResep"
                                    label="text"
                                    @input="changeJenis($event,rowTable.index)"
                                    :reduce="v=>v.value"
                                />
                                <VValidate :name="'Jenis '+(rowTable.index+1)" v-model="data[rowTable.index].aurdf_jenis" :rules="{required : 1}" />
                            </template>
                            <template v-else>
                                {{rowTable.value == 1 ? 'Obat' : rowTable.value == 2 ? 'Alat Kesehatan' : 'Racikan'}}
                            </template>
                        </template>
                        <template #cell(aurdf_item_id)="rowTable">
                            <template v-if="rowTable.item.isEdit">
                                <span class="d-flex">
                                    <template v-if="data[rowTable.index]['aurdf_jenis'] == 1">
                                        <v-select style="flex:1;" placeholder="Pilih Item" class="med-selection select-paging"
                                            @search="filterObat($event,rowTable.index);countLen();" v-model="data[rowTable.index].aurdf_item_id" :options="mObatPreferensi"
                                            label="text" @input="selectJumlahSatuan($event,rowTable.index)" :clearable="true" :reduce="v=>v.value"
                                            :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                            <template slot="selected-option">
                                                <span v-b-tooltip.hover.right placement='right' :title="rowTable.item.aurdf_nama">{{ rowTable.item.aurdf_nama }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <span>{{ option.text }}</span>
                                            </template>
                                            <template #list-footer>
                                                <li class="list-paging">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <b-button @click="changePageObat(rowTable.item.currentPage,rowTable.index,'min','data')" size="sm" variant="transparent"
                                                            class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                                        <small>Halaman {{rowTable.item.currentPage}} dari {{ceilData(rowTable.item.totalRows/10)}}</small>
                                                        <b-button @click="changePageObat(rowTable.item.currentPage,rowTable.index,'plus','data')" size="sm" variant="transparent"
                                                            class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                                    </div>
                                                </li>
                                            </template>
                                        </v-select>
                                    </template>

                                    <template v-else-if="data[rowTable.index]['aurdf_jenis'] == 2">
                                        <v-select style="flex:1;" placeholder="Pilih Item" v-model="data[rowTable.index].aurdf_item_id"
                                            :options="mAlatKesehatan" label="text" @input="selectJumlahSatuanAlkes($event,rowTable.index)" :clearable="true"
                                            :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                                <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <span>{{ option.text }}</span>
                                            </template>
                                        </v-select>
                                    </template>

                                    <template v-else-if="data[rowTable.index]['aurdf_jenis'] == 3">
                                        <v-select style="flex:1;" placeholder="Pilih Item" v-model="data[rowTable.index].aurdf_item_id"
                                            :options="mRacikan" label="text" @input="selectJumlahSatuanRacikan($event,rowTable.index)" :clearable="true"
                                            :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                                <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <span>{{ option.text }}</span>
                                            </template>
                                        </v-select>
                                    </template>
                                    <a href="javascript:;" @click="data[rowTable.index]['aurdf_item_id'] = 99999; data[rowTable.index]['aurdf_nama'] = 'Lainnya'" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                                </span>
                                <VValidate :name="'Nama Item '+(rowTable.index+1)" v-model="data[rowTable.index]['aurdf_item_id']" :rules="{required : 1}" />

                                <div v-if="data[rowTable.index]['aurdf_item_id'] == 99999">
                                    <b-form-input type="text" v-model="data[rowTable.index]['aurdf_item_lainnya']" class="form-control" id="itemCount" />
                                    <VValidate :name="'Item Lainnya '+(rowTable.index+1)" v-model="data[rowTable.index]['aurdf_item_lainnya']" :rules="{required : 1}" />
                                </div>
                                
                                <!--
                                <small class="d-block">Dosis:
                                    {{data[rowTable.index]['aurdf_dosis'] || "-"}} {{data[rowTable.index]['aurdf_satuan_dosis']}}</small>
                                -->

                                <a v-if="data[rowTable.index].aurdf_jenis == 3 && data[rowTable.index]['aurdf_item_id']"
                                    href="javascript:;"
                                    @click="toRacikan(data[rowTable.index],rowTable.index)"
                                    data-popup="tooltip"
                                    title=""
                                    data-toggle="modal"
                                    data-target="#modalRacikanDetail"
                                    class="font-weight-semibold border-bottom"
                                    data-original-title="Lihat Detail Resep"
                                >Lihat Detail Racikan</a>
                            </template>
                            <template v-else>
                                <a v-if="rowTable.item.aurdf_jenis == 3" href="javascript:;"  @click="toRacikan(data[rowTable.index],rowTable.index)" data-popup="tooltip" title="" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom" data-original-title="Lihat Detail Resep">{{rowTable.item.aurdf_nama||"-"}}</a>
                                <span v-else>
                                    <template v-if="rowTable.value !== 99999">
                                            {{rowTable.item.aurdf_nama||"-"}}
                                    </template>
                                    <template v-else>
                                            {{rowTable.item.aurdf_item_lainnya||"-"}}
                                    </template>
                                </span><br/>
                                <!--
                                <small>Dosis : {{rowTable.item.aurdf_dosis || "-"}} {{rowTable.item.aurdf_satuan_dosis||"-"}}</small>
                                -->
                            </template>
                        </template>
                        <template #row-details="rowTable">
                            <div class="card mb-0" v-if="!editRacikanDokter">
                                <div class="card-body">
                                    <h5 class="card-title font-weight-semibold">Komposisi Racikan</h5>
                                    <p>{{rowRacikan.aurdf_jumlah||"-"}} {{rowRacikan.aurdf_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri dari:</p>
                                    <table class="table table-striped table-sm table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="40">#</th>
                                                <th>Nama Obat</th>
                                                <th>Qty</th>
                                                <th>Frekuensi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(v,k) in rowRacikan.aurdf_racikan" :key="k+'rowracik'">
                                                <td>{{k+1}}</td>
                                                <td>{{getObat(v.value)||"-"}}</td>
                                                <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                                                <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <a href="javascript:;" v-if="rowReg.aur_is_farmasi_done !== 'Y'"  @click="openFormRacikan = true;openRacikan = false" class="btn btn-link">
                                    <div class="icon-pencil7"></div>
                                    Ubah Komposisi Racikan
                                    </a>
                                    <button @click="rowTable.toggleDetails" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Mengerti</button>
                                </div>
                            </div>
                        </template>
                        <template #cell(aurdf_jumlah)="rowTable">
                            <template v-if="rowTable.item.isEdit">
                                <div class="input-group">
                                    <b-form-input type="text" v-model="data[rowTable.index]['aurdf_jumlah']" :formatter="$parent.number" class="form-control" id="itemCount" />
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{data[rowTable.index]['aurdf_satuan']}}</span>
                                    </div>
                                </div>
                                <VValidate :name="'Jumlah '+(rowTable.index+1)" v-model="data[rowTable.index]['aurdf_jumlah']" :rules="{required : 1}" />
                            </template>
                            <template v-else>
                                {{rowTable.value||"-"}} {{rowTable.item.aurdf_satuan||"-"}}
                            </template>
                        </template>
                        <template #cell(aurdf_frekuensi)="rowTable">
                            <template v-if="rowTable.item.isEdit">
                                <span class="d-flex">
                                    <v-select style="flex:1;" placeholder="Pilih Frekuensi" v-model="data[rowTable.index].aurdf_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <a href="javascript:;" @click="data[rowTable.index]['aurdf_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                                </span>
                                <VValidate :name="'Frekuensi '+(rowTable.index+1)" v-model="data[rowTable.index]['aurdf_frekuensi']" :rules="{required : 1}" />
                                <div v-if="data[rowTable.index]['aurdf_frekuensi'] == 99999">
                                    <b-form-input type="text" v-model="data[rowTable.index]['aurdf_frekuensi_lainnya']" class="form-control" id="itemCount" />
                                    <VValidate :name="'Frekuensi Lainnya '+(rowTable.index+1)" v-model="data[rowTable.index]['aurdf_frekuensi_lainnya']" :rules="{required : 1}" />
                                </div>
                            </template>
                            <template v-else>
                                <span v-if="rowTable.value !== 99999">
                                    {{rowTable.item.mdo_name || "-"}}
                                </span>
                                <span v-else>
                                    {{rowTable.item.aurdf_frekuensi_lainnya || "-"}}
                                </span>
                            </template>
                        </template>
                        <template #cell(aurdf_keterangan)="rowTable">
                            <template v-if="rowTable.item.isEdit">
                                <b-textarea v-model="data[rowTable.index].aurdf_keterangan" name="itemNotes" id="itemNotes" rows="2" placeholder="cth. obat dihabiskan" class="form-control"></b-textarea>
                            </template>
                            <template v-else>
                                {{rowTable.value || "-"}}
                            </template>
                        </template>
                        <template #custom-foot v-if="rowReg.aur_is_farmasi_done !== 'Y'">
                            <tr>
                                <td class="text-center" colspan="99">
                                    <b-button :disabled="openAddObat" @click="openObat()" variant="outline-primary" class="btn alpha-blue">
                                        <i class="icon-plus2 mr-1 align-middle"></i>
                                        <span class="align-middle">Tambah Resep</span>
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                    </b-table>
                    
                    <div class="card-body p-3" v-if="rowReg.aur_is_farmasi_done !== 'Y'">
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                <label for="">Catatan Perubahan Resep</label>
                                <textarea v-model="row.aur_catatan_perubahan_resep" class="form-control" rows="3"></textarea>
                                </div>
                            </div>
                            
                            <div class="col-md-6" v-if="rowReg.aur_is_submit_farmasi != 'Y'">
                                <label for="poliSelection">Pilih Status Farmasi
                                <small class="text-danger"> *</small>
                                </label>
                                <v-select placeholder="Pilih Status Farmasi"
                                v-model="rowReg.aur_is_verif_farmasi"
                                :options="Config.mr.mVerifFarmasi" label="text" :clearable="true"
                                :reduce="v=>v.value"></v-select>
                            </div>

                            <div class="col-md-6" v-if="rowReg.aur_is_submit_farmasi == 'Y'">
                                <label for="poliSelection">Status Farmasi
                                </label><br/>
                                <span class="badge bg-primary mb-1" v-if="rowReg.aur_is_verif_farmasi == 'N'">Dalam proses</span>
                                <span class="badge bg-warning mb-1" v-else-if="rowReg.aur_is_verif_farmasi == 'B'">Verifikasi BPJS</span>
                                <span class="badge bg-warning mb-1" v-else-if="rowReg.aur_is_verif_farmasi == 'A'">Verifikasi Asuransi</span>
                            </div>
                        </div>  
                    </div>

                    <div class="card-footer">
                        <div class="d-flex">
                        <button @click="toList()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                        
                        <button @click="doOpenTelaah" type="button" class="btn btn-primary mr-2">Telaah Resep</button>

                        <button v-if="rowReg.aur_is_submit_farmasi == 'Y'" @click="downloadRM" type="button" class="btn btn-primary mr-2">Unduh Resep</button>
                        
                        <button v-if="rowReg.aur_is_submit_farmasi !== 'Y'" type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
            </b-form>
        </validation-observer>
        </div>

        <validation-observer ref="VFormRacikan">
        <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
            <div class="modal-body p-0">
                <div class="alert alert-info">
                <span>Saat ini Anda sedang melakukan perubahan komposisi racikan <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
                </div>
                <div class="form-group">
                <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
                <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in rowRacikan.aurdf_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                        <v-select placeholder="Pilih Obat" @input="selectObat($event,k)" v-model="rowRacikan.aurdf_racikan[k]['value']" :options="getMasterObat(rowRacikan.aurdf_racikan,k)" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        
                        <VValidate 
                            :name="'Obat #'+(k+1)" 
                            v-model="rowRacikan.aurdf_racikan[k]['value']" 
                            :rules="{required : 1}"
                        />
                    </td>
                    <td>
                        <div class="input-group">
                        <input v-model="rowRacikan.aurdf_racikan[k]['dosis']" type="text" class="form-control" placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;" v-model="rowRacikan.aurdf_racikan[k]['satuan_dosis']" type="text" class="form-control" placeholder="cth. Kaplet"></div>
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.aurdf_racikan[k]['jumlah']" :formatter="number" class="form-control" placeholder="cth. 5"/>
                        <div class="input-group-append"><span class="input-group-text">{{rowRacikan.aurdf_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                        </div>
                        
                        <VValidate 
                            :name="'Jumlah #'+(k+1)" 
                            v-model="rowRacikan.aurdf_racikan[k]['jumlah']" 
                            :rules="{required: 1}"
                        />
                    </td>
                    <td>
                        <a href="javascript:;" @click="rowRacikan.aurdf_racikan.splice(k,1)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td colspan="9" class="text-center">
                        <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                        </a>
                    </td>
                    </tr>
                </tfoot>
                </table>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4">
                    <div>
                    <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                    <div class="input-group">
                        <b-form-input type="text" :formatter="number" v-model="rowRacikan.aurdf_jumlah" class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan"/>
                        <div class="input-group-append">
                        <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.aurdf_satuan" :options="mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>
                    <VValidate 
                        name="Jumlah" 
                        v-model="rowRacikan.aurdf_jumlah" 
                        :rules="{required : 1}"
                    />
                    <VValidate 
                        name="Satuan" 
                        v-model="rowRacikan.aurdf_satuan" 
                        :rules="{required : 1}"
                    />
                    </div>
                </div>
            </div>
            <div class="modal-footer p-0 mt-3">
                <a href="javascript:;" @click="openFormRacikan = false" data-toggle="modal" data-target="#updateRacikanFarmasi" class="btn btn-link" data-dismiss="modal">
                Batal
                </a>
                <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left" data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
            </div>
        </b-modal>
        </b-form>
        </validation-observer>

        <b-modal
            v-model="isOpenTelaahResep"
            :title="'Telaah Resep'"
            size="lg"
            body-class="p-3"
            @ok.prevent="submitTelaah"
            ok-title="Submit"
        >
            <div class="mb-3">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Telaah Obat</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="50%">
                        <div>
                            <label for="tepatPasien">Tepat Pasien</label>
                            <b-form-group>
                                <b-form-radio-group
                                    :options="Config.mr.yesNoOptV2"
                                    v-model="rowTelaah.auft_to_tepat_pasien"
                                />
                            </b-form-group>
                        </div>
                      </td>
                      <td width="50%">
                        <b-form-textarea v-model="rowTelaah.auft_to_tepat_pasien_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Tepat Obat</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_to_tepat_obat"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_to_tepat_obat_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Tepat Dosis</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_to_tepat_dosis"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_to_tepat_dosis_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Tepat Waktu</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_to_tepat_waktu"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_to_tepat_waktu_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Tepat Rute</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_to_tepat_rute"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_to_tepat_rute_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div>
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th colspan="2">Kejelasan Penulisan R/</th>
                    </tr>
                    <tr>
                      <th>Telaah Resep</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-info">
                      <td colspan="2"><span class="font-weight-semibold text-info-700">Administratif</span></td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Nama Dokter</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_tr_nama_dokter"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_tr_nama_dokter_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Identitas Pasien</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_tr_identitas_pasien"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_tr_identitas_pasien_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Tgl. Resep</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_tr_tgl_resep"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_tr_tgl_resep_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="table-info">
                      <td colspan="2"><span class="font-weight-semibold text-info-700">Farmasetis</span></td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Nama Obat</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_fr_nama_obat"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_fr_nama_obat_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Dosis Obat</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_fr_dosis_obat"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_fr_dosis_obat_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Bentuk Sediaan</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_fr_bentuk_sediaan"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_fr_bentuk_sediaan_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Rute</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_fr_rute"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_fr_rute_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Lama Pemberian</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_fr_lama_pemberian"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_fr_lama_pemberian_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="table-info">
                      <td colspan="2"><span class="font-weight-semibold text-info-700">Klinis</span></td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Duplikasi</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_kl_duplikasi"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_kl_duplikasi_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Alergi</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_kl_alergi"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_kl_alergi_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Interaksi Obat</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_kl_interaksi_obat"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_kl_interaksi_obat_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">BB (Pasien Anak)</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_kl_bb"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_kl_bb_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div>
                                <label for="tepatPasien">Kontra Indikasi</label>
                                <b-form-group>
                                    <b-form-radio-group
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="rowTelaah.auft_kl_kontra_indikasi"
                                    />
                                </b-form-group>
                            </div>
                        </td>
                        <td width="50%">
                            <b-form-textarea v-model="rowTelaah.auft_kl_kontra_indikasi_ket" name="ketTepatObat" id="ketTepatObat" rows="2" class="form-control"></b-form-textarea>
                        </td>
                    </tr>
                  </tbody>
                  <tbody>    
                    <tr>
                      <td width="50%">
                        <div>
                          <label for="namaPenelaah">Nama Penelaah Resep</label>
                        </div>
                      </td>
                      <td width="50%">
                        <b-form-input v-model="rowTelaah.auft_kl_nama_penelaah" type="text" class="form-control" id="namaPenelaah" />
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </b-modal>

    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  extends: GlobalVue,
  data() {
    return {
        isBtnType : '',
        isShowBtn : false,
        rowReg: {},
        data: [],
        mObat: [],
        mRacikan: [],
        mTypeSatuan: [],
        mAlatKesehatan: [],
        mObatPreferensi: [],
        mDosis: [],
        rowEdit: {},
        openAddObat: false,
        openRacikan: false,
        openFormRacikan: false,
        rowRacikan: {},
        showPatient: false,
        dataDokter: {},
        dataFarmasi: false,
        editRacikanDokter: false,
        obatLen: 0,
        currentPage: 1,
        farmTableFields: [
            { key: 'aksi', thAttr: { width: 48 } },
            { key: 'aurdf_jenis', label: 'Jenis', thAttr: { width: 160 } },
            { key: 'aurdf_item_id', label: 'Nama', thAttr: { width: 320 } },
            { key: 'aurdf_jumlah', label: 'Jumlah', thAttr: { width: 150 } },
            { key: 'aurdf_frekuensi', label: 'Frekuensi', thAttr: { width: 210 } },
            { key: 'aurdf_keterangan', label: 'Keterangan' },
        ],
        // rowEdit: {},
        openPanggil: false,
        canConfirm: false,
        isPanggil: false,

        isOpenTelaahResep: false,
        rowTelaah: {}
    }
  },
  mounted(){
    this.apiGet()
    this.apiGetMaster()
    setTimeout(()=>{
        if(!(this.mRacikan||[]).length){
            this.Config.mr.configResep[2]['disabled'] = true
        }
    },1000)
    setTimeout(()=>{
        this.initSticky()
    },1000)
  },
  methods: {
      
    submitTelaah(){
        this.loadingOverlay = true
        let data = this.rowTelaah
        data.type = 'submit-data-telaah'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$swal({
                title: resp.message,
                icon: "success",
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if(result.value){
                    this.isOpenTelaahResep = false
                }
            })
        })
    },
    doOpenTelaah(){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-data-telaah', id: this.rowReg.aur_id}}, 
            "POST"
        ).then(res=>{
            this.rowTelaah = res.data.dataTelaah
            this.isOpenTelaahResep = true
            this.loadingOverlay = false
        })
    },
    
    toList(){
        this.$router.push({ name: 'UGDFarmasi' }).catch(()=>{})  
    },
    apiGetMaster(){
        let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
        Gen.apiRest(
            "/get/"+this.modulePage+
            slugs+`?master=1&regId=${this.pageSlug}`, 
            {}
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    ceilData(value){
        return Math.ceil(value)
    },
    countLen(){
        setTimeout(()=>{
            this.obatLen = $('.sp-col ul li').length
        },100)
    },
    downloadRM(){
        if(this.rowReg.aures_jaminan == 3){
            let data = {exptype: 'xlsx', type: "export", id: this.$route.params.pageSlug}
            let self = this

            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'downloadSEPCaseMixUGD'}?token=IXs1029102asoaksoas102901290&isFarmasi=1`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `resep_${this.row.ap_code}_${moment().format("YYYY-MM-DD")}.pdf`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        }else{
            let data = {
                exptype: 'pdf', type: "export", pasienId: this.rowReg.aur_ap_id, regId: this.$route.params.pageSlug        
            }
            let self = this
            self.loadingOverlay = true
            $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/report/${'dataFarmasiUGD'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                            responseType: 'blob'
                    },
                    success: data => 
                    {
                            self.loadingOverlay = false
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(data)
                            link.download = `resep_${self.rowReg.aur_reg_code}-${moment().format("YYYY-MM-DD")}.pdf`
                            link.click()
                    },
                    fail: data => {
                            self.loadingOverlay = false
                            alert('Not downloaded')
                    }
            })
        }
       
    },
    
    initSticky(){
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if(PageHeaderSticky){    
            const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
            if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                PageHeaderToggle.addEventListener('click', function(e){
                // e.preventDefault()
                PageHeaderSticky.classList.toggle('page-header-sticky-open')
                if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                    PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                }else{
                    PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                }
                })    
            } 
        }
    },
    
    getObat(val){
        let index = this.mObat.findIndex(x => x.value == val)        
        if(index !== -1){
            return this.mObat[index]['text']
        }else{
            return null
        }
    },

    filterObat: _.debounce(function (e,k){
        if(e){
            this.data[k]['search'] = e          
            let id = []
            for(let i = 0; i < (this.data||[]).length; i++){
                if(this.data[i]['aurdf_item_id'] && this.data[i]['aurdf_jenis'] == 1){
                id.push(this.data[i]['aurdf_item_id'])
                }
            }
            Gen.apiRest('/do/'+'UGDFarmasi', {data: {type:'select-obat', search: e, id: id}}, 'POST').then(res=>{
                this.mObatPreferensi = res.data.data
                this.data[k]['currentPage'] = 1
                this.data[k]['totalRows'] = res.data.totalRows
            })
        }
    },10),

    
    openObat(v=null){
        let data = {
            aurdf_aur_id: this.rowReg.aur_id,
            aurdf_aurm_id: this.row.aurm_id,
            aurdf_date: moment().format("YYYY-MM-DD"),
            aurdf_jenis : 1,
            aurdf_item_id: null,
            aurdf_nama : null,
            aurdf_jumlah : null,
            aurdf_satuan : null,
            aurdf_frekuensi : null,
            aurdf_frekuensi_lainnya : null,
            aurdf_keterangan : null,
            
            aurdf_rute : null,
            aurdf_waktu_pemberian : null,
            aurdf_tindak_lanjut_pemberian : null,
            aurdf_perubahan_aturan_pakai : null,
            
            id : null
        }
        data.type = 'add-resep-dokter'
        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.apiGet()
            this.openAddObat = true
        })
    },
    addObat(){
        this.rowRacikan.aurdf_racikan.push({
            value : null,
            dosis : null,
            satuan_dosis : null,
            jumlah : null,
            satuan_jumlah : null,
        })
    },
    getMasterObat(data,k){
        const sldMenu = _.map(_.filter(data||[], (v2, k2)=>{
            return k2!=k
        }), v2=>{
            return v2.value
        })
        return _.filter(this.mObat, v2=>{
            return sldMenu.indexOf(v2.value)<=-1
        })
    },
    selectObat(e,k){
        let index = this.mObat.findIndex(x => x.value == e)
        if(index !== -1){
            let data = this.mObat[index]
            this.rowRacikan.aurdf_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aurdf_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aurdf_racikan[k]['satuan_jumlah'] = data.satuan            
        }
    },
    toRacikan(v,k=null){
        console.log(v)
        this.loadingOverlay = true
        let data = {
            id: v.aurdf_item_id,
            type: 'get-racikan-data'
        }

        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.data[k]['_showDetails'] = true
        })
    },
    selectJumlahSatuan(e,k){
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
             this.data[k].aurdf_satuan = res.data.satuan
             this.data[k].aurdf_nama = res.data.nama
             this.data[k].aurdf_racikan = null

             this.data[k].aurdf_dosis = res.data.dosis
             this.data[k].aurdf_satuan_dosis = res.data.satuan_dosis
             this.data[k].aurdf_item_lainnya = null
        })
    },
    selectJumlahSatuanAlkes(e,k){
        let data = {
            id: e,
            type: 'get-by-alkes'
        }
        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
             this.data[k].aurdf_satuan = res.data.satuan
             this.data[k].aurdf_nama = res.data.nama
             this.data[k].aurdf_racikan = null

             this.data[k].aurdf_dosis = null
             this.data[k].aurdf_satuan_dosis = null
             this.data[k].aurdf_frekuensi = null
             this.data[k].aurdf_frekuensi_lainnya = null
             this.data[k].aurdf_item_lainnya = null
        })
    },
    selectJumlahSatuanRacikan(e,k){
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
             this.data[k].aurdf_satuan = res.data.satuan
             this.data[k].aurdf_nama = res.data.nama
             this.data[k].aurdf_racikan = res.data.data

             this.data[k].aurdf_dosis = null
             this.data[k].aurdf_satuan_dosis = null
             this.data[k].aurdf_frekuensi = null
             this.data[k].aurdf_frekuensi_lainnya = null
             this.data[k].aurdf_item_lainnya = null
        })
    },
    
    resetData(){
        this.openFormRacikan = false          
        this.apiGet()          
    },

    removeObat(v,k){
        let data = {
            type : 'remove-resep-dokter',
            id: v.aurdf_id
        }

        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.data.splice(k,1)
            this.openAddObat = false
        })
    },
    doSubmitRacikan(){
        this.$refs['VFormRacikan'].validate().then(success=>{
            if(success){
                let data = this.rowRacikan
                let idx = this.data.findIndex(x=>x.aurdf_id == this.rowRacikan.aurdf_id)
                this.data[idx]['aurdf_satuan'] = this.rowRacikan.aurdf_satuan
                this.data[idx]['aurdf_jumlah'] = this.rowRacikan.aurdf_jumlah
                this.data[idx]['aurdf_racikan'] = this.rowRacikan.aurdf_racikan
                this.openFormRacikan = false
            }else{
                setTimeout(()=>{
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                },500)
            }
        })
    },
    changeJenis(e,k){
         this.data[k].aurdf_item_id = null
         this.data[k].aurdf_frekuensi = null
         this.data[k].aurdf_frekuensi_lainnya = null
    },
    
    changePageObat(page,k,act,col){
        if(act == 'min'){
            if(page > 1){
                page -= 1
                this[col][k]['currentPage'] -= 1 
            }
        }else{
            if(page < this.ceilData(this[col][k]['totalRows']/10)){
                page += 1
                this[col][k]['currentPage'] += 1 
            }
        }

        let data = {
            type : 'select-paging-obat',
            page : page,
            search : this[col][k]['search']
        }

        Gen.apiRest(
            "/do/"+'UGDFarmasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.mObatPreferensi = res.data.data
        })
    },

    doSubmitObat(v){
        this.$refs['VFormSubmit'].validate().then(success=>{
            if(success){
                let data = v
                data.id = v.aurdf_id
                data.type = 'add-resep-dokter'
                Gen.apiRest(
                    "/do/"+'UGDFarmasi',
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.apiGet()
                    this.openAddObat = false
                })
            }else{
                setTimeout(()=>{
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                },500)
            }
        })
    },

    doSubmit(){
        let invalid = 0
        for(let i = 0; i < (this.data||[]).length; i++){
            if(this.data[i]['isEdit']){ 
                invalid += 1
            }
        }

        if(!this.rowReg.aur_is_verif_farmasi){
            return this.$swal({
                title: "Tidak Dapat Lanjut",
                html: "Mohon isi status Farmasi",                
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

        if(invalid){
            return this.$swal({
                title: "Tidak Dapat Lanjut",
                html: "Mohon simpan perubahan obat terlebih dahulu",                
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(result => {
                if (result.value) {
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
        }

        this.$refs['VFormSubmit'].validate().then(success=>{
            if(success){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.loadingOverlay = true
                        let data = this.rowReg
                        data.ausd_id = this.dataDokter.ausd_id
                        data.type = 'submit-farmasi'

                        Gen.apiRest(
                            "/do/"+'UGDFarmasi',
                            {data:data}, 
                            "POST"
                        ).then(res=>{
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok'
                            }).then(result => {
                                if (result.value) {
                                    this.apiGet()    
                                }
                            })
                        }).catch(err=>{
                            this.loadingOverlay = false
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.$parent.doSetAlertForm(err)
                        })
                    }
                })
            }else{
                setTimeout(()=>{
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                },500)
            }
        })
    },
    
  },
  watch:{
    $route(){
        this.apiGet()
        this.apiGetMaster()
    }
  }
      
}

</script>
