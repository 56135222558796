<template>
    <div class="content-wrapper">
        <template v-if="isList">
        <div class="page-header">
        <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex">
            <h4>
                <span class="font-weight-semibold">UGD Farmasi</span>
            </h4>
            </div>
        </div>
        </div>
        <div class="content pt-0">
            <div class="">
            

                <div class="card">
                
                    <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                        <li class="nav-item"><a href="javascript:;" @click="changeTab('R')" :class="isObat == 'R' ? 'nav-link active' : 'nav-link'">Obat Racikan</a></li>
                        <li class="nav-item"><a href="javascript:;" @click="changeTab('J')" :class="isObat == 'J' ? 'nav-link active' : 'nav-link'" class="nav-link">Obat Jadi</a></li>
                    </ul>
                    <div class="bg-white card-header">
                        <div class="row align-items-center">
                            <div class="col-md-5">
                                <div class="form-row">
                                    <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                    
                                    <div class="col-md-8" v-if="isshowTable('aur_reg_date')">
                                        <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-lg-7">
                                <div class="form-row justify-content-end">
                                    <!--
                                    <div class="col-md-5">
                                        <div class="lbl_status_pasien bg_red">
                                            <h3>
                                                <i class="icon-users2"></i>
                                                {{getQueue}} PASIEN ANTRI
                                            </h3>

                                        </div>
                                    </div>
                                    -->
                                    
                                    <div class="col-md-5">
                                        <div class="lbl_status_pasien bg_red">
                                            <h3>
                                                <i class="icon-user-check"></i>
                                                {{getPending}} Pending
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="lbl_status_pasien bg_green">
                                            <h3>
                                                <i class="icon-user-check"></i>
                                                {{getSelesai}} Selesai
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-row">
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                    -->
                                    <div class="col-md-4">
                                        <b-form-group>
                                            <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status_farmasi" :options="Config.mr.configStatusFarmasi" label="text" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                    <input class="form-control"
                                        v-model="filter.search"
                                        @input="doSearch"
                                        placeholder="Ketik Nama/No. RM Pasien"
                                    />
                                    <div class="form-control-feedback">
                                        <i class="icon-search4 text-indigo"></i>
                                    </div>
                                        
                                    <b-button
                                        class="ml-1 d-inline-flex align-items-center"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive sticky-table">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
                            <thead>
                                <tr>
                                
                                    <th>No.</th>
                                    <th>Triase</th>
                                    <th>Aksi</th>
                                    <th>No Antrean</th>
                                    <th v-if="isshowTable('aur_reg_code')">No. Kedatangan</th>
                                    <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                    <th>Diagnosa</th>
                                    <th v-if="isshowTable('aus_name')">Status</th>
                                    <th v-if="isshowTable('aur_reg_date')">Tanggal Daftar</th>
                                    <th v-if="isshowTable('ap_code')">No. RM</th>
                                    <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                    <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                    <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                    <th v-if="isshowTable('ap_usia')">Usia</th>
                                    <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                    <th v-if="isshowTable('aur_is_lab')">Penunjang</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loadingTable" >
                                <template v-for="(v,k) in dataReg" >
                                    <tr :key="k" :class="v.aur_process_status_ppa_lab == 'QUEUE' ? 'table-info' : v.aur_process_status_ppa_lab == 'VOID' ? 'table-danger' : ''">
                                        <td>{{v.aur_no_urut_farmasi||"-"}}</td>
                                        
                                        <template v-if="v.aur_triase_id">
                                            <td :class="'triase_'+v.color">
                                            <strong class="font-weight-semibold text-white">{{v.triase_type||"-"}}</strong>
                                            </td>
                                        </template>
                                        <template v-else>
                                        <td>
                                            <span class="font-weight-semibold text-warning-600">Belum Ada</span>
                                        </td>
                                        </template>
                                        
                                        <td>
                                            <a 
                                            v-if="v.aur_is_called_farmasi != 'N' && v.aur_is_called_farmasi != 'Y' && v.aur_is_submit_farmasi == 'Y'" @click="requestPanggil(v)" href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip" title="Panggil Pasien" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger ml-1" data-original-title="Panggil Pasien"><i class="icon-megaphone"></i></a>

                                            <a v-else @click="periksaPasien(v)" href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip" title="Lihat Pasien" class="ml-2 btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Panggil Pasien"><i class="icon-eye"></i></a>
                                        </td>

                                        <td>
                                            <small class="font-weight-semibold" style="font-size: 18px;">
                                                {{v.aur_no_antrian||"-"}}
                                            </small>
                                        </td>

                                        
                                        <td v-if="isshowTable('aur_reg_code')">
                                        {{v.aur_reg_code||"-"}}
                                        <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                        <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                        <td v-if="isshowTable('ap_fullname')">
                                        <a href="javascript:;" @click="periksaPasien(v)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right data-popup="tooltip" title="Buat Resep" > {{uppercaseWord(v.ap_fullname)||"-"}}</a>
                                        <br/>
                                        <small class="text-danger" style="font-size: 10px;" v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

                                        <p>{{v.ap_nik||"-"}}</p>
                                        </td>

                                        <td>
                                                
                                            <a href="javascript:;" @click="lihatDiagnosa(v,k)" 
                                            v-b-tooltip.click.html="v.diagnosa" class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                                        </td>  

                                        <td v-if="isshowTable('aus_name')">
                                            <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                                VOID
                                            </div>
                                            <template v-else>
                                                <div v-if="v.aur_is_farmasi_done == 'Y'" :class="`badge bg-success mb-1`">Selesai</div>
                                                <div v-else :class="`badge bg-warning mb-1`">Pending</div>

                                                <div v-if="v.aur_is_called_farmasi == 'Y'" :class="`badge bg-success`">Selesai Dipanggil</div>
                                                <div v-else-if="v.aur_is_called_farmasi == 'B'" :class="`badge bg-warning`">Belum Datang</div>
                                                <div v-else-if="v.aur_is_called_farmasi == 'N'" :class="`badge bg-danger`">Tidak Hadir</div>
                                                <div v-else :class="`badge bg-info`">Belum Dipanggil</div>

                                            </template>
                                        </td>

                                        <td v-if="isshowTable('aur_reg_date')">
                                        {{v.aur_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>  
                                        

                                        <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                        <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                        <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                        <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                        <td v-if="isshowTable('ap_usia')">
                                        {{v.ap_usia_with_ket||"-"}}
                                        <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                        </td>
                                        <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                        <td v-if="isshowTable('aur_is_lab')">
                                            <div v-if="v.aur_is_radiologi == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Radio</span>
                                            </div>
                                            <div v-if="v.aur_is_lab == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Lab</span>
                                            </div>
                                            <span v-if="v.aur_is_lab !== 'Y' && v.aur_is_radiologi !== 'Y'"> - </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-if="loadingTable">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="loadingInvinite"> 
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                            </tbody>
                            <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                                <tr>
                                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                        Data Tidak Ditemukan
                                    </th>
                                </tr>                
                            </tbody>                
                        </table>
                        <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                            <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-left"></i>
                            </a>
                            <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
                <b-modal 
                    v-model="openFilter"
                    :title="'Filter Kolom'"
                    size="sm"
                    ok-title="Terapkan"
                    @ok="terapkan()"
                >
                    <div class="row">
                        <div class="col-md-12">
                        <div class="wrap_line">
                            <div class="form-check p-0">
                                <label class="form-check-label d-flex">
                                <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                                Tampilkan Semua
                                </label>
                            </div>
                            <hr class="my-2">
                            <div class="pl-2">
                                <b-form-group>
                                    <b-form-checkbox-group
                                        id="checkbox-block"
                                        v-model="selectedFilter"
                                        :options="optionFilter"
                                        name="filter"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
        </template>

        <template v-else>
            <Form :row.sync="row" v-bind="passToSub"/>
        </template>

        
        <b-modal 
            v-model="openPanggil"
            :title="'Konfirmasi Pemanggilan Pasien'"
            size="md"
            body-class="p-2"
            hide-footer
        >
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-megaphone"></i>
                </div>
                <h6 class="mb-0">Memanggil Pasien dengan No. Antrian {{rowEdit.aur_no_antrian}} atas Nama</h6>
                <h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>
                
                <div class="mt-1 mb-3">                
                    <a href="javascript:;" v-if="!isPanggil && rowEdit.aur_no_antrian" @click="canConfirm=true;panggilAntrean(rowEdit)" class="btn btn-info btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-megaphone"></i></b>
                        Panggil Antrean
                    </a>
                    
                    <a href="javascript:;" v-else-if="isPanggil" class=" btn btn-secondary btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-megaphone"></i></b>
                        Memanggil ...
                    </a>
                </div>

                <template>
                    <h6>Apakah pasien hadir?</h6>
                    <b-form-group class="mt-3">
                    <b-form-radio-group
                        :options="Config.mr.StatusPanggil"
                        v-model="rowEdit.aur_is_called_farmasi"
                    />
                    </b-form-group>
                    <div class="mt-4">
                        <a href="javascript:;" @click="konfirmasiPanggil()" class="btn btn-success btn-labeled btn-labeled-left">
                        <b><i class="icon-checkmark"></i></b>
                        Konfirmasi
                        </a>
                    </div>
                </template>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import Form from './Form.vue'

// import Datepicker from 'vuejs-datepicker'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'aur_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'aus_name' },
                { text: 'Tanggal Daftar', value: 'aur_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Cara Bayar', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'cg_label' },
                { text: 'Penunjang', value: 'aur_is_lab' },
            ],
            selectedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
            ],
            acceptedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            openVerifikasiLab: false,
            isPanggil: false,
            detailData: false,
            rowDetail: {},
            changeJadwal: false,
            totalTindakan: 0,
            getPending: 0,
            getSelesai: 0,
            isObat: 'R',

        }
    },
    computed: { 
        disabledDates() {
            return {
                to: new Date(moment().format('YYYY-MM-DD'))
            }
        },   
        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.dataGizi.asg_data||[]).length; i++){
                if(this.dataGizi.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        selectedLabInput() {
            let data = []
            for (let ik = 0; ik < (this.rowDetail.aupdl_data || []).length; ik++) {
                for (let jk = 0; jk < (this.rowDetail.aupdl_data[ik]['dubData'] || []).length; jk++) {
                    for (let kk = 0; kk < (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                    if (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                        data.push(this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                    }
                }
            }
            if (this.rowDetail.aupdl_hasil_lainnya) {
              data.push(this.rowDetail.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },
        passToSub(){
            return _.assign(this.passToSubComp||{})
        }
    },
  	components:{DateRangePicker,
      Form
    //   Datepicker
    },
    methods: {
        isSameName(name){
            let index = this.dataReg.findIndex(x=>x.ap_fullname == name)
            let count = 0
            for (let i = 0; i < this.dataReg.length; i++) {
                if (this.dataReg[i].ap_fullname === name) {
                    count++
                }
            }
            if(count > 1){
                return "text-warning"
            }else{
                return ""
            }
        },
        changeTab(isObat){
            this.isObat = isObat
            this.apiGet(true)
        },
        lihatDiagnosa(v,k){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'lihat-diagnosa', id: v.aur_id}}, 
                "POST"
            ).then(res=>{
                this.dataReg[k]['diagnosa'] = res.data        
            })
        },
        submitJadwalBaru(){
            console.log(this.rowEdit)
            this.$refs['VFormJadwal'].validate().then(success=>{
                if(success){
                    let data = this.rowEdit
                    data.type = "change-date-lab"
                    this.loadingOverlay = true   

                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: data}, 
                        "POST"
                    ).then(res=>{
                        this.$swal({
                            title: `Jadwal Lab Berhasil Diganti`,
                            icon: 'success',
                        })
                        this.loadingOverlay = false
                        this.changeJadwal = false
                        this.apiGet()
                    })

                }
            })
        },  
        changeJadwalLab(v){
            this.rowEdit = v
            this.rowEdit.aur_reg_ppa_lab_new = null
            this.changeJadwal = true
        },
        isShowHeadLab(i) {
            let isData = 0
            for (let j = 0; j < (this.rowDetail.aupdl_data[i]['dubData'] || []).length; j++) {
                for (let k = 0; k < (this.rowDetail.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                    if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                        isData += 1
                    }
                }
            }
            return isData
        },
        isShowHeadSubHeadLab(i, j) {
            let isData = 0
            for (let k = 0; k < (this.rowDetail.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                    isData += 1
                }
            }
            return isData
        },
            
        isShowLab(i, j, k) {
            let isData = 0
            if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                isData += 1
            }
            return isData
        },
        showDataLab(v){
            this.loadingOverlay = true    
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'detail-lab', id: v.aur_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.rowDetail = res.data.dataDokter                
                this.detailData = true
            })
        },
        panggilAntrean(data){
            // to specific user
            this.isPanggil = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'panggil-antrean', id: data.aur_id}}, 
                "POST"
            ).then(res=>{
                let row = res.data.row
                row.to = "UGDFarmasi"
                this.$socket.emit('panggil_pasien', row)
                setTimeout(()=>{
                    this.isPanggil = false
                },5000)
            })
        },
        verifyData(v){
            this.rowEdit = v
            this.openVerifikasiLab = true
        },
        doRefreshData(){
            this.apiGet(true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return moment().format('YYYY-MM-DD') <= this.to24Hours(v.aurm_created_date) && v.aur_is_created_upla !== 'Y'
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_lab_ugd_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet()
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },100),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(isLoad = false){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isLoad){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage, 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null,
                        isObat: this.isObat
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.totalData = resp.dataReg.total

                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                    this.totalTindakan = resp.totalTindakan
                }   
            })

            // get total antrian/tindakan
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/antrian?antrian=true', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data                
                this.getPending = resp.getPending
                this.getSelesai = resp.getSelesai
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if(scrollEl && heightEl){
                if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                    if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                        if(this.modulePage == 'Dashboard'){
                            this.loadingInvinite = true
                            this.filter.page = (+this.$route.query.page||1) + 1

                            this.$router.push({
                                name:this.modulePage,
                                query:_.clone(this.filter)
                            }).catch(()=>{})
                        }
                    }
                }
                if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                    this.onScrollPoint = false
                    window.removeEventListener('wheel', this.tableScrollFunction, false)
                }
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },


        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        
        redirect(v){
            this.$router.push({ name: 'UGDFormRadiologi', params: { pageSlug: v.ap_id, rmNo: v.aurm_id}, query: {regId: v.aur_id} }).catch(()=>{})
        },

        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    Array.from(responsiveTableScrollActions).forEach(action => {
                        action.addEventListener('click', e => {
                            const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                            console.log(scrollAmount)
                            responsiveTable.scroll({
                                left: scrollAmount,
                                behavior: 'smooth'
                            })
                        })
                    })
                }
            }
        },
        
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction(cmp){
            return $(`#${cmp}`).is(':empty')
        },

        periksaPasien(v){
            
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'duplicate-resep-dokter', id: v.aur_id, aurm_id: v.aurm_id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                if(this.user.levelId == 1){
                    this.$router.push({ name: 'UGDFarmasi', params: { pageSlug: v.aur_id } }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'UGDFarmasi', params: { pageSlug: v.aur_id } }).catch(()=>{})
                }                
            })
            
        },
        
        requestPanggil(v){
            this.rowEdit = v
            this.rowEdit.aur_is_called_farmasi = 'Y'
            this.openPanggil = true
        },

        
        konfirmasiPanggil(){
            this.rowEdit.type = 'panggil-pasien-farmasi'
            this.loadingOverlay = true            
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: this.rowEdit}, 
                "POST"
            ).then(res=>{
                this.$socket.emit('refresh_data', {to : "UGDFarmasi"})                       

                let dataSocket = {
                    to : "UGDFarmasi",
                    from : "UGDFarmasi",
                    no_antrian: this.rowEdit.aur_no_antrian
                }
                
                this.$socket.emit('done_penunjang', dataSocket)

                let resp = res.data
                this.$swal({
                    title: resp.message,
                    icon: "success",
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                this.openPanggil = false
                this.loadingOverlay = false
                this.apiGet(true)
            })
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_lab_ugd_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        this.filter.process_status_farmasi = 'N'
        
        if(this.$route.query.page != 1 && this.isList){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)

        const stickyTable = document.querySelector('.sticky-table')        
        if(stickyTable){
            stickyTable.addEventListener('scroll', this.handleScroll)
            window.addEventListener('scroll', this.windowTableScroller)
            this.onScrollPoint = false
        }
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(){
           this.apiGet()
           setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        },
        'openPanggil'(v){
            if(!v && Object.keys(this.rowEdit||{}).length){
                setTimeout(()=>{
                    this.rowEdit.aur_is_called_farmasi = 'D'    
                },1000)
            }
        }   
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
  .dashboard-table th, .dashboard-table td {
    white-space: normal !important;  
  }
</style>